export const Load = () => {
    return (
        <div className="text-gray-400 flex w-full justify-center">
            <div
                className="w-16 h-16 rounded-full border-8 border-t-transparent border-r-transparent border-b-transparent animate-spin"

            ></div>
        </div>
    );
};
