// src/types.js

export const ADD_TO_CART = "ADD_TO_CART";
export const ADD_FILTER = "ADD_FILTER";
export const REMOVE_ITEM = "REMOVE_ITEM";
export const REMOVE_FILTER = "REMOVE_FILTER";
export const CLEAR_CART = "CLEAR_CART";
export const UPDATE_QUANTITY = "UPDATE_QUANTITY";
export const CLEAR_FILTER = "CLEAR_FILTER";
export const SET_ALL_DATA = "SET_ALL_DATA"; // Added this line
export const SET_USER = "SET_USER"; // Added this line
